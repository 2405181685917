var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"o-alert-list"},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('v-select',{attrs:{"label":"Typ","items":_vm.alertData.types,"clearable":"","menu-props":{ closeOnClick: true, closeOnContentClick: true },"dense":"","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(_vm._s(item.icon))]),_c('span',[_vm._v(_vm._s(item.text))])],1)]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){_vm.model.type = null}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.model.type ? '' : 'primary'}},[_vm._v(_vm._s(_vm.model.type ? 'mdi-radiobox-blank' : 'mdi-radiobox-marked'))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Wszystkie")])],1)],1),_c('v-divider')]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.model.type == item.value ? 'primary' : ''}},[_vm._v(_vm._s(_vm.model.type == item.value ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank'))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1)],1)]}}]),model:{value:(_vm.model.type),callback:function ($$v) {_vm.$set(_vm.model, "type", $$v)},expression:"model.type"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('v-select',{attrs:{"label":"Status","items":_vm.alertData.statuses,"clearable":"","multiple":"","menu-props":{ closeOnClick: false, closeOnContentClick: false },"dense":"","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.color || 'grey'}},[_c('span',[_vm._v(_vm._s(item.text))])])]}},{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.toggleStatus(true)}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.model.status.length <= 0 ? 'primary' : ''}},[_vm._v(_vm._s(_vm.statusSelectDefaultIcon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Niezakończony")])],1)],1),_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.toggleStatus(false)}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.model.status.length > 0 ? 'primary' : ''}},[_vm._v(_vm._s(_vm.statusSelectIcon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Wszystkie")])],1)],1),_c('v-divider')]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.model.status.includes(item.value) ? 'primary' : ''}},[_vm._v(_vm._s(_vm.model.status.includes(item.value) ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":item.color || 'grey'}},[_vm._v("mdi-circle")])],1)],1)]}}]),model:{value:(_vm.model.status),callback:function ($$v) {_vm.$set(_vm.model, "status", $$v)},expression:"model.status"}})],1),(_vm.hasAccessTo('employee') || _vm.hasManyCompanies())?_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('m-company-filter',{attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(_vm.model.company),callback:function ($$v) {_vm.$set(_vm.model, "company", $$v)},expression:"model.company"}})],1):_vm._e()],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"loading":_vm.state.loading,"headers":_vm.state.headers,"items":_vm.state.items,"options":_vm.state.options,"server-items-length":_vm.state.total,"footer-props":{ itemsPerPageOptions: [10, 25, 50, 100] },"mobile-breakpoint":1904},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)},"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [(_vm.getAlertType(item.type))?_c('span',{staticClass:"text-no-wrap"},[_c('v-badge',{attrs:{"dot":"","color":"red","overlap":"","left":"","value":item.status === 'to_do'}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.getAlertTypeIcon(item.type)))])],1),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.getAlertType(item.type)))])],1):_c('span',{staticClass:"text-no-wrap"},[_c('v-badge',{attrs:{"dot":"","color":"red","overlap":"","left":"","value":item.status === 'to_do'}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert")])],1),_c('span',{staticClass:"ml-2"},[_vm._v("Inny")])],1)]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [(item.date)?_c('v-list-item',{staticClass:"text-no-wrap pa-0",attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm._f("moment")(item.date,'from', 'now')))]),_c('v-list-item-subtitle',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm._f("moment")(item.date,'DD.MM.YYYY')))])],1)],1):_c('v-divider')]}},{key:"item.mileage",fn:function(ref){
var item = ref.item;
return [(typeof item.mileage === 'number')?_c('span',{staticClass:"text-no-wrap grey--text"},[_vm._v(_vm._s(item.mileage.toLocaleString())+" km")]):_c('v-divider')]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [(_vm.getAlertEndDate(item))?_c('span',{staticClass:"text-no-wrap d-flex align-center"},[_c('v-chip',{staticClass:"mr-4",attrs:{"small":"","color":_vm.getAlertEndDateColor(item)}},[_vm._v(_vm._s(_vm._f("moment")(_vm.getAlertEndDate(item),'DD.MM.YYYY')))]),_c('span',{staticClass:"d-flex align-center text-no-wrap"},[_c('v-icon',{staticClass:"mr-1",attrs:{"left":"","color":_vm.getAlertEndDateColor(item)}},[_vm._v("mdi-alert")]),_c('span',{class:((_vm.getAlertEndDateColor(item)) + "--text")},[_vm._v(_vm._s(_vm.getAlertTimeLeft(item)))])],1)],1):_c('v-divider')]}},{key:"item.endMileage",fn:function(ref){
var item = ref.item;
return [(_vm.getAlertEndMileage(item))?_c('span',{staticClass:"text-no-wrap d-flex align-center"},[_c('v-chip',{staticClass:"mr-4",attrs:{"small":"","color":_vm.getAlertEndMileageColor(item)}},[_vm._v(_vm._s(_vm.getAlertEndMileage(item))+" km")]),_c('span',{staticClass:"d-flex align-center text-no-wrap"},[_c('v-icon',{staticClass:"mr-1",attrs:{"left":"","color":_vm.getAlertEndMileageColor(item)}},[_vm._v("mdi-alert")]),_c('span',{class:((_vm.getAlertEndMileageColor(item)) + "--text")},[_vm._v(_vm._s(_vm.getAlertMileageLeft(item))+" km")])],1)],1):_c('v-divider')]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(_vm.getAlertStatus(item.status))?_c('span',{staticClass:"text-no-wrap"},[_c('v-chip',{attrs:{"small":"","color":_vm.getAlertStatusColor(item.status)}},[_vm._v(_vm._s(_vm.getAlertStatus(item.status)))])],1):_c('v-divider')]}},{key:"item.vehicle",fn:function(ref){
var item = ref.item;
return [(_vm.getAlertVehicle(item))?_c('v-list-item',{staticClass:"text-no-wrap pa-0",attrs:{"dense":""}},[_c('v-list-item-action',{staticClass:"mr-2 mt-3 mb-3"},[_c('v-btn',{attrs:{"icon":"","small":"","to":{ name: 'panel.vehicle.view', params: { id: _vm.getAlertVehicle(item).id } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-car")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('router-link',{attrs:{"to":{ name: 'panel.vehicle.view', params: { id: _vm.getAlertVehicle(item).id } }}},[_vm._v(_vm._s(_vm.getAlertVehicle(item).registration))])],1),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.getAlertVehicle(item).name))])],1)],1):_c('v-divider')]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [(_vm.getAlertCompany(item))?_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.getAlertCompany(item).name))]):_c('v-divider')]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }