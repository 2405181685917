












































































































































import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";
import useAlert from "@/use/alert";
import useUser from "@/use/user";

export default defineComponent({
  components: {
    MCompanyFilter: () => import("@/components/molecules/m-company-filter.vue"),
  },

  setup(_, { root }) {
    const axiosInstance = root.$store.getters[
      "api/getInstance"
    ] as AxiosInstance;
    const { hasAccessTo, hasManyCompanies } = useUser({ root });

    const alerts = ref<any[]>([]);

    const {
      alertData,
      getAlertType,
      getAlertTypeIcon,
      getAlertTimeLeft,
      getAlertMileageLeft,
      getAlertStatus,
      getAlertStatusColor,
      getAlertEndDate,
      getAlertEndDateColor,
      getAlertEndMileageColor,
      getAlertEndMileage,
      getAlertVehicle,
      getAlertCompany,
    } = useAlert();

    const state = reactive({
      loading: true,
      headers: [
        { value: "type", text: "Typ", sortable: false, width: 1 },
        {
          value: "date",
          text: "Data wyświetlenia alertu",
          sortable: false,
          width: 1,
        },
        {
          value: "mileage",
          text: "Przebieg wyświetlenia alertu",
          sortable: false,
          width: 1,
        },
        {
          value: "endDate",
          text: "Termin wykonania działania",
          sortable: false,
          width: 1,
        },
        {
          value: "endMileage",
          text: "Przebieg wykonania działania",
          sortable: false,
          width: 1,
        },
        { value: "status", text: "Status", sortable: false, width: 1 },
        { value: "vehicle", text: "Pojazd", sortable: false, width: 3 },
        { value: "company", text: "Firma", sortable: false, width: 3 },
      ],
      items: computed(() => alerts.value.map((alert) => ({ ...alert }))),
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      total: 0,
    });

    const model = reactive({
      type: null,
      status: [] as string[],
      company: null as any,
    });

    const fetch = () => {
      const { page, itemsPerPage } = state.options as DataOptions;

      const fetchData = {
        type: model.type || undefined,
        status: model.status || undefined,
        filter: {
          company: model.company ? model.company.id : undefined,
        },
      };

      state.loading = true;

      axiosInstance
        .get("alert", { params: { page, itemsPerPage, ...fetchData } })
        .then(({ data }) => {
          alerts.value = data.alerts;
          state.total = data.total;
        })
        .catch(() => {
          alerts.value = [];
          state.total = 0;
        })
        .finally(() => (state.loading = false));
    };

    onMounted(fetch);
    watch(model, () => (state.options.page = 1), { deep: true });
    watch(model, fetch, { deep: true });
    watch(model, () => nextTick(fetch), { deep: true });
    watch(model, () => setTimeout(fetch, 100), { deep: true });
    watch(() => state.options, fetch, { deep: true });

    const statusSelectAllSelected = computed(
      () => model.status.length == alertData.statuses.length
    );
    const statusSelectSomeSelected = computed(
      () => model.status.length > 0 && !statusSelectAllSelected.value
    );

    const statusSelectIcon = computed(() => {
      if (statusSelectAllSelected.value) return "mdi-close-box";
      if (statusSelectSomeSelected.value) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    });

    const statusSelectDefaultIcon = computed(() => {
      if (model.status.length > 0) return "mdi-radiobox-blank";
      return "mdi-radiobox-marked";
    });

    const onRowClick = (event: MouseEvent, data: any) => {
      root.$router.push({
        name: "panel.alert.view",
        params: { id: data.item.id },
      });
    };

    const toggleStatus = (none = false) => {
      root.$nextTick(() => {
        if (none || statusSelectAllSelected.value) {
          model.status = [];
        } else {
          model.status = alertData.statuses.map((status) => status.value);
        }
      });
    };

    return {
      hasAccessTo,
      hasManyCompanies,

      state,
      model,

      alertData,
      getAlertType,
      getAlertTypeIcon,
      getAlertTimeLeft,
      getAlertMileageLeft,
      getAlertStatus,
      getAlertStatusColor,
      getAlertEndDate,
      getAlertEndDateColor,
      getAlertEndMileageColor,
      getAlertEndMileage,
      getAlertVehicle,
      getAlertCompany,

      statusSelectAllSelected,
      statusSelectSomeSelected,
      statusSelectIcon,
      statusSelectDefaultIcon,

      onRowClick,
      toggleStatus,
    };
  },
});
