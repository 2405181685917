import moment from 'moment';

export default function useAlert() {
  const alertData = {
    types: [
      {
        value: 'oil_change',
        text: 'Wymiana oleju',
        icon: 'mdi-oil-level',
      },
      {
        value: 'leasing',
        text: 'Koniec leasingu',
        icon: 'mdi-script-text-key-outline',
      },
      {
        value: 'insurance',
        text: 'Koniec ubezpieczenia',
        icon: 'mdi-shield-alert-outline',
      },
      {
        value: 'technical_inspection',
        text: 'Badanie techniczne',
        icon: 'mdi-clipboard-alert-outline',
      },
      { value: 'tire_change', text: 'Zmiana opon', icon: 'mdi-car-tire-alert' },
    ],
    statuses: [
      { value: 'to_do', text: 'Nowy', color: 'warning' },
      { value: 'in_progress', text: 'W trakcie', color: 'primary' },
      { value: 'done', text: 'Zakończony', color: 'success' },
    ],
  };

  const getAlertType = (code: string) =>
    alertData.types.find((type) => type.value === code)?.text || null;

  const getAlertTypeIcon = (code: string) =>
    alertData.types.find((type) => type.value === code)?.icon || null;

  const getAlertStatus = (code: string) =>
    alertData.statuses.find((status) => status.value === code)?.text || null;

  const getAlertStatusColor = (code: string) =>
    alertData.statuses.find((status) => status.value === code)?.color || null;

  const getAlertEndDate = (alert: any) => {
    if (alert.insurance) return alert.insurance.endDate || null;
    if (alert.leasing) return alert.leasing.endDate || null;
    if (alert.service) return alert.service.nextServiceDate || null;
    else return null;
  };

  const getAlertEndDateColor = (alert: any) => {
    const endDate = getAlertEndDate(alert)

    if (endDate) {
      const daysLeft = moment(endDate).diff(moment(), 'days');
      const monthsLeft = moment(endDate).diff(moment(), 'months');

      if (alert.insurance) {
        if (daysLeft <= 0) return 'warning';
        else if (daysLeft <= 45) return 'success';
        else return 'primary';
      } else if (alert.leasing) {
        if (monthsLeft <= 0) return 'warning';
        else if (monthsLeft <= 6) return 'success';
        else return 'primary';
      } else if (alert.service) {
        if (daysLeft <= 0) return 'warning';
        else if (daysLeft <= 15) return 'success';
        else return 'primary';
      } else {
        return 'primary';
      }
    } else {
      return 'primary';
    }
  };

  const getCurrentMileage = (alert: any, vehicle: any = null) => {
    let currentMileage = 0
    if (vehicle) {
      currentMileage = vehicle && vehicle.mileages && vehicle.mileages[0] && vehicle.mileages[0].mileage || 0
    } else {
      currentMileage = alert.vehicle && alert.vehicle.mileages && alert.vehicle.mileages[0] && alert.vehicle.mileages[0].mileage || 0
    }
    return currentMileage
  }

  const getAlertEndMileage = (alert: any) => {
    if (alert.insurance) return null;
    if (alert.leasing) return null;
    if (alert.service) return alert.service.nextServiceMileage || null;
    else return null;
  };

  const getAlertEndMileageColor = (alert: any, vehicle: any = null) => {
    const endMileage = getAlertEndMileage(alert)

    if (endMileage) {
      const currentMileage = getCurrentMileage(alert, vehicle);
      const mileageLeft = endMileage - currentMileage;

      if (mileageLeft <= 0) return 'warning'
      else if (mileageLeft <= 3000) return 'success'
      else return 'primary';
    } else {
      return 'primary';
    }
  }

  const getAlertVehicle = (alert: any) => {
    if (alert.vehicle) return alert.vehicle;
    else return null;
  };

  const getAlertCompany = (alert: any) => {
    if (alert.vehicle && alert.vehicle.company) return alert.vehicle.company;
    else return null;
  };

  const getAlertTimeLeft = (alert: any) => {
    const endDate = getAlertEndDate(alert)

    if (endDate) {
      const daysLeft = moment(endDate).diff(moment(), 'days');
      const monthsLeft = moment(endDate).diff(moment(), 'months');

      if (alert.leasing) {
        if (monthsLeft <= 0) return '0 mies.';
        else return `${monthsLeft} mies.`;
      } else {
        if (daysLeft <= 0) return '0 dni';
        else if (daysLeft == 1) return '1 dzień';
        else return `${daysLeft} dni`;
      }
    } else {
      return null
    }
  }

  const getAlertMileageLeft = (alert: any, vehicle: any = null) => {
    const endMileage = getAlertEndMileage(alert)
    
    if (endMileage) {
      const currentMileage = getCurrentMileage(alert, vehicle);
      const mileageLeft = endMileage - currentMileage;

      return Math.max(mileageLeft, 0);
    } else {
      return null;
    }
  }

  return {
    alertData,
    getAlertType,
    getAlertTypeIcon,
    getAlertStatus,
    getAlertStatusColor,
    getAlertEndDate,
    getAlertEndDateColor,
    getAlertEndMileage,
    getAlertEndMileageColor,
    getAlertVehicle,
    getAlertCompany,
    getAlertTimeLeft,
    getAlertMileageLeft,
  };
}
